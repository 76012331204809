import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import ScrollIndicator from './ScrollIndicator';
import './product.css';

const products = [
  {
    name: 'VIBRO SIFTER 12',
    features: [
      'Available in 12”, 20”, 30”, 36”, 48”, 60”.',
      'Machine Design as per cGMP.',
      'All Contact parts SS316 with and Non-Contact parts with SS 304 or clad.',
      'Basic machine Non Flame Proof Construction.',
      'Machine on PU Castor Wheels.',
      'Easily changeover sieve & cleaning process using Clamps.'
    ],
    image: './img/product-showcase/Shifter.png',
  },

  {
    name: 'RAPID MIXER GRANULATOR',
    features: [
      'Available in Lab Scale (2.5-15ltrs), max upto 600 ltrs.',
      'Machine Design as per cGMP.',
      'With Interchangeable design of Mixing bowl.',
      'All Contact parts AISI SS316 with Mirror Finish.',
      'Basic machine with Non Flame Proof Construction.',
      'Table Top Machine design.'
    ],
    image: './img/product-showcase/rmg .png',
  },

  {
    name: 'FLUID BED DRYER(FBD/FBP)',
    features: [
      'Available in Lab Scale (2.5-15ltrs), max upto 600 ltrs.',
      'Available in Steam and Electric Heater Type.',
      'For lab version, Special table top model is available.',
      'FBP provision version is available.',
      'HMI operated machine can be setted up',
      'Machine Design as per cGMP.',
    ],
    image: './img/product-showcase/FBD.png',
},

{
  name: 'Auto-Coater',
  features: [
    'Available in Lab Scale to 60”.',
    'Bowl interchangeable facility.',
    'high quality tablet coating system is designed.',
    'Basic machine is in Flame Proof Motor Construction.',
    'Machine Design as per cGMP.',

  ],
  image: './img/product-showcase/Autocoater.png',
},

{
  name: 'Coating Pan',
  features: [
    'Available in Lab Scale to 72”.',
    'Bowl interchangeable facility.',
    'Basic machine is in Flame Proof Motor Construction.',
    'Machine Design as per cGMP.',
  ],
  image: './img/product-showcase/Coating Pan.png',
},


{
  name: 'Blender',
  features: [
    'Available in 2.5 to 5000kg.',
    'Auto-Initial Positioning.',
    'Blender are in form: Octagonal, Conta, Double Cone, V- Blender, Bin Blender, Cage Blender.',
    'Basic machine is in Flame Proof Motor Construction.',
    'Machine Design as per cGMP.',

  ],
  image: './img/product-showcase/Blender.png',
},


{
  name: 'Paste Kettle',
  features: [
    'Available in Lab Scale to 150 ltr”.',
    'Proper jacket vessels are provided.',
    'Homogenizer system is available.',
    'Basic machine is in Flame Proof Motor Construction.',
    'Machine Design as per cGMP.',
  ],
  image: './img/product-showcase/Paste Kettle.png',
},

{
  name: 'Tray Dryer',
  features: [
    'Available in Customised no. of trays',
    'Rack system or trolley system.',
    'Basic machine is in Flame Proof Motor Construction.',
    'Machine Design as per cGMP.',
  ],
  image: './img/product-showcase/TrayDryer.png',
},
{
  name: 'Ointment Plant',
  features: [
    'Available in lab scale to 2000kg.',
    'Machine Design as per cGMP.',
    'All Contact parts SS316 with and Non-Contact parts with SS 304 or clad.',
    'Basic machine Non Flame Proof Construction with jacket vessels.',
    'lab scale Machine on PU Castor Wheels and on single platform.',
    'In steam and electric heater versions.'
  ],
  image: './img/product-showcase/Ointment.png',
},

{
  name: 'Multimill and Co-mill',
  features: [
    'Available in lab co mill, production co-mill and standard multi mill.',
    'Machine Design as per cGMP.',
    'All Contact parts SS316 with and Non-Contact parts with SS 304 or clad.',
    'Basic machine Non Flame Proof Construction.',
       ],
  image: './img/product-showcase/Multimill.png',
},

{
  name: 'Acessories',
  features: [
    'Available in SS 304 and SS 316 as per contact part required',
    'Customised size and volume',
  ],
  image: './img/product-showcase/Accessories.png',
},


];

const ProductCard = ({ product }) => {
  return (
    <div className="product-card">
      <img src={product.image} alt={product.name} className="product-image" />
      <div className="product-info">
        <h3>{product.name}</h3>
        {/* <p>Model: {product.model}</p> */}
        <h4>Machine Features</h4>
        <ul>
          {product.features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Product = () => {
  return (
    <div className="product-page">
      <Navbar />
      <ScrollIndicator />
      {products.map((product, index) => (
        <ProductCard key={index} product={product} />
      ))}
      <Footer />
    </div>
  );
};

export default Product;
