import Home from './Components/Home.js';
import Contact from './Components/Contact.js';
import About from './Components/About.js';
import QrCode from './Components/QrCode.js';
import Store from './Components/Store.js';
import Product from './Components/Product.js';
import Turnkey from './Components/Turnkey.js';
import Consultancy from './Components/Consultancy.js';
import ScrollToTop from './Components/ScrollToTop';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { createContext} from "react";
export const UserContext = createContext();

const Routing = () => {
  return (
   <>
   <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route exact path="/About" element={<About/>} />
        <Route exact path="/Contact" element={<Contact/>} />
        <Route exact path="/QrCode" element={<QrCode/>} />
        <Route exact path="/Store" element={<Store/>} />
        <Route exact path="/Product" element={<Product/>} />
        <Route exact path="/Turnkey" element={<Turnkey/>} />
        <Route exact path="/Consultancy" element={<Consultancy/>} />
      </Routes>
   </>
  )
}
function App() {
 return (
<>
      <Router>
          <Routing/>
      </Router>
    </>)
}

export default App;