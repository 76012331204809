// import React, { useEffect, useState } from 'react';
// import './ScrollIndicator.css'; // Create this CSS file

// const ScrollIndicator = () => {
//   const [showIndicator, setShowIndicator] = useState(true);
//   const [scrollTimeout, setScrollTimeout] = useState(null);

//   useEffect(() => {
//     const handleScroll = () => {
//       if (scrollTimeout) {
//         clearTimeout(scrollTimeout);
//       }

//       setShowIndicator(true);

//       const timeout = setTimeout(() => {
//         setShowIndicator(false);
//       }, 2000); // 2 seconds delay before hiding

//       setScrollTimeout(timeout);
//     };

//     window.addEventListener('scroll', handleScroll);

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//       if (scrollTimeout) {
//         clearTimeout(scrollTimeout);
//       }
//     };
//   }, [scrollTimeout]);

//   return showIndicator ? (
//     <div className="scroll-indicator">
//       <p>Scroll down to explore more products</p>
//     </div>
//   ) : null;
// };

// export default ScrollIndicator;
import React, { useEffect, useState } from 'react';
import './ScrollIndicator.css'; // Create this CSS file

const FloatingScrollButton = () => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setVisible(false);
      } else {
        setVisible(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return visible ? (
    <div className="floating-scroll-button">
      <p>Swipe up to see more</p>
      <div className="arrow-icon"></div>
    </div>
  ) : null;
};

export default FloatingScrollButton;
