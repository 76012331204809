import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './turnkey.css'; // Custom CSS for styling and animation

const Turnkey = () => {
  return (
    <div>
        <Navbar />
    
        <div className="turnkey-project-page">
        <div className="image-container">
        <img
          src="./img/product-showcase/Projections-amico.png" // Replace with actual image path
          alt="Turnkey Project"
          className="project-image"
        />
      </div>
      <div className="content-container">
        <h2 className="project-title">Turnkey Projects</h2>
        <ul className="bullet-points">
            <li>Preparing plant layout drawings in accordance with WHO, MHRA, PICS, and USFDA specifications.</li>
            <li>Developing a detailed project report including total project cost, production capacity, operational costs, and utility consumption.</li>
            <li>Managing project coordination and follow-up with various agencies until project completion.</li>
            <li>Providing expert guidance, supervision, conducting site meetings, and troubleshooting.</li>
            <li>Assisting in the selection of machines and equipment at an economical cost.</li>
            <li>Managing project documentation to ensure regulatory compliance.</li>
            <li>Overseeing validation activities for processes and equipment.</li>
            <li>Designing utilities and assisting in securing manufacturing licenses for commercial production.</li>
          </ul>
          </div>
      
    </div>
    <Footer />
    </div>
  );
};

export default Turnkey;
