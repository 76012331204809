import React from 'react'
import Navbar from './Navbar.js';
import Footer from './Footer.js';

function Contact() {
  return (
    <>
    <Navbar/>
    <section class="home4-contact-info">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
                    <div class="section-title-2">
                      <br/>
                        <h6 className="intro">Connect with us</h6>
                        <h2 class="title">Contact Us</h2><br/>
                        
                    </div>
                </div>
            </div>
            
                <div class="row">
                    
                
                <div class="col-lg-3 col-sm-6 col-12 wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
                        <a href="mailto:info@hsmpharmasolutions.com?subject=Inquiry%20About%20Your%20Services&body=Hello%2C%20I%20would%20like%20more%20information%20about%20your%20product%20offerings.%20Please%20get%20in%20touch%20with%20me%20at%20your%20earliest%20convenience.%20%0A%0AThank%20you.%0A%0A">
                        <div class="single-h4-contact">
                            <div class="icon-box">
                                <span class="icon color-2"><i class="fal fa-envelope"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title">Email Address</h2>
                                <p class="text">info@hsmpharmasolutions.com<br/>info.hsmps@gmail.com<br/>.</p>
                            </div>
                        </div>
                        </a>
                    </div>
                    <div class="col-lg-3 col-sm-6 col-12 wow fadeInUp" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div class="single-h4-contact">
                            <div class="icon-box">
                                <span class="icon color-3"><i class="fal fa-phone"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title">Phone Number</h2>
                                <p class="text"><br/>+91 91062 91200 <br/> +91 97145 26822</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-lg-3 col-sm-6 col-12 wow fadeInUp" data-wow-delay="100ms" data-wow-duration="1500ms">
                        <a href="https://drive.google.com/drive/folders/1oI8wKpj2i-F74cKYoYrKRiBcpuD4sjGI?usp=sharing" target="_blank" rel="noopener noreferrer">
                        <div class="single-h4-contact">
                            <div class="icon-box">
                                <span class="icon color-4"><i class="fal fa-file"></i></span>
                            </div>
                            <div class="content">
                                <h2 class="title">Explore Brochures</h2>
                                <p class="text"><br/>Tap to Explore brochure for more info</p>
                            </div>
                        </div>
                        </a>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-12 wow fadeInUp" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div class="single-h4-contact">
                            <a href="https://maps.app.goo.gl/xA1bXDs4skAYncidA" target="_blank" rel="noopener noreferrer">
                            <div class="icon-box">
                                <span class="icon color-1"><i class="fal fa-map-marker-alt"></i></span>
                            </div>
                             <div className="content">
                                <h2 class="title">Office Address</h2>
                                <p className="text"> F/201, Kanha Luxuria, Opp Bapod Water Tank, Waghodia Road,
                                Vadoara -390025</p>
                             </div>
                            </a>

                        </div>
                    </div>
                    
                </div>
            
        </div>
    </section>
    <Footer/>
    </>
  )
}

export default Contact;

