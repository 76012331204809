import React from "react";
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import {NavLink} from "react-router-dom";


function Home() {
  return (
    <>
    <Navbar></Navbar>
    <div>
        <section className="home2-hero-area">
            <div className="home2-hero-banner wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms"><img className="item-bounce" src="img/product-showcase/hero-banner.png" alt=""/></div>
            {/* <h1 className="home2-bg-text">Showcase</h1> */}
            <div className="home2-hero-container">
                <div className="row">
                    <div className="col-lg-5 col-md-7 col-12">
                        <div className="home2-hero-content">
                            <h4 className="intro">Future of Pharma</h4>
                            <h2 className="title">Excellence in Pharma Machinery.</h2>
                            <p className="text">We provide robust machinery and turnkey solutions that ensure full regulatory compliance, from plant layout design to obtaining manufacturing licenses. Our expert team excels in ongoing and new product formulation, cost and quality maintenance during production, and regulatory support. We manage project documentation, validation activities, SOP development, and space validation, all while enhancing machine capabilities and ergonomics for optimal performance.</p>
                            {/* <ul className="links">
                                <li><a href="#" className="home2-button-2 link btn-hvr-anim-top">Get Started Now</a></li>
                                <li><a href="#" className="home2-button-1 link btn-hvr-anim-top">Learn More</a></li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="home2-feature-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-3 col-12">
                        <div className="section-title-2">
                            <h6 className="intro">Core Feautres</h6>
                            <h2 className="title">Discover The Future With HSM Pharma</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="1500ms">
                        <div className="home2-single-feature">
                            <div className="icon-box">
                                <span className="color-1 icon"><img src="img/assets/internet-of-things.png" alt=""  width="40" height="40"/></span>
                            </div>
                            <div className="content">
                                <h2 className="title">Comprehensive Product Line</h2>
                                <p className="text">HSM Pharma provides complete pharmaceutical equipment solutions.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay="100ms" data-wow-duration="1500ms">
                        <div className="home2-single-feature">
                            <div className="icon-box">
                                <span className="color-2 icon"><img src="img/assets/Smooth-experience.png" alt=""  width="40" height="40"/></span>
                            </div>
                            <div className="content">
                                <h2 className="title">High-Quality Standards</h2>
                                <p className="text">HSM Pharma's machinery is built for quality, durability, and compliance.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay="200ms" data-wow-duration="1500ms">
                        <div className="home2-single-feature">
                            <div className="icon-box">
                                <span className="color-3 icon"><img src="img/assets/worldwide-shipping.png" alt=""  width="40" height="40"/></span>
                            </div>
                            <div className="content">
                                <h2 className="title">Global Reach and Expertise</h2>
                                <p className="text">HSM Pharma has a strong global presence and 35+ years industrial expertise.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 wow fadeInUp animated" data-wow-delay="300ms" data-wow-duration="1500ms">
                        <div className="home2-single-feature">
                            <div className="icon-box">
                                <span className="color-4 icon"><img src="img/assets/time-management.png" alt=""  width="40" height="40"/></span>
                            </div>
                            <div className="content">
                                <h2 className="title">Strong R&D Capabilities</h2>
                                <p className="text">HSM Pharma stays ahead with cutting-edge technology.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        
        <section className="machine-accessory-area">
  <div className="container">
    <div className="section-title">
      <h2 className="section-title">Our Machines & Accessories</h2>
    </div>
    <div className="content-grid">
      {/* Machines Column */}
      <div className="machines-column">
        <h3 className="column-heading">MACHINES</h3>
        <ul className="item-list" style={{ listStyleType: 'inherit', paddingLeft: '20px' }}>
          <li className="item">Vibro Sifter</li>
          <li className="item">Rapid Mixer Granulator</li>
          <li className="item">Fluid Bed Processor (FBD/FBP)</li>
          <li className="item">Multi Mill and Co-Mill</li>
          <li className="item">Blender</li>
          <li className="item">Conventional Coating Pan</li>
          <li className="item">Auto Coater</li>
          <li className="item">Tray Dryer</li>
          <li className="item">Ointment Plant Machine</li>
          <li className="item">Paste Kettle (with Homogenizer)</li>
        </ul>
        <NavLink className="home2-button-2 link btn-hvr-anim-top" to="/Product">Learn more</NavLink>
      </div>
      {/* Accessories Column */}
      <div className="accessories-column">
        <h3 className="column-heading">ACCESSORIES</h3>
        <ul className="item-list" style={{ listStyleType: 'inherit', paddingLeft: '20px' }}>
          <li className="item">Coating Tank</li>
          <li className="item">Paste Tank</li>
          <li className="item">Hoppers</li>
          <li className="item">Shifter Sleeves</li>
          <li className="item">Scoops</li>
          <li className="item">Table</li>
          <li className="item">Wheel Bins</li>
          <li className="item">Cross-Over/Pass-Over Bench</li>
          <li className="item">Pass Box</li>
          <li className="item">Change Room Cupboard</li>
        </ul>
        
        <NavLink className="home2-button-2 link btn-hvr-anim-top" to="/Product">Learn more</NavLink>
        
        
      </div>
    </div>
  </div>
</section>




        <section className="home2-built-with-area">
            <div className="home2-built-bg wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms"><img src="img/product-showcase/built-bg.png" alt=""/></div>
            <div className="home2-built-banner wow fadeInLeft animated" data-wow-delay="900ms" data-wow-duration="1500ms">
                <img className="item-bounce" src="img/product-showcase/built-banner.png"  width="610" height="600" alt="image"/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-12">
                        <div className="home2-built-with-content">
                            <div className="section-title-2">
                                <h6 className="intro">USP Product</h6>
                                <h2 className="title">Cornerstone FBD22-L05</h2>
                            </div>
                            <p className="text">
                            <ul className="item-list" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li className="item">COMPACT DESIGN</li>
                                <li className="item">USED FOR DIVERSED RANGE OF MATERIALS</li>
                                <li className="item">INTRODUCING 3 CARTRIDGE SYSTEM</li>
                                <li className="item">THROUGHOUT OPTIMAL DRYING</li>
                                <li className="item">LESS MAINTAINANCE EASY TO USE</li>
                                <li className="item">EASY TO USE</li>
                            </ul>
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="home2-experience-area">
            <div className="home2-experience-bg wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="1500ms"><img src="img/product-showcase/supported-bg.png" alt=""/></div>
            <div className="home2-experience-banner wow fadeInRight animated" data-wow-delay="900ms" data-wow-duration="1500ms"><img className="item-bounce" src="img/product-showcase/supported-banner1.jpg" width="770" height="235" alt=""/></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-12">
                        <div className="home2-expierince-content">
                            <div className="section-title-2">
                                <h6 className="intro">Turnkey Projects</h6>
                                <h2 className="title">Comprehensive Solutions for Pharma Manufacturing</h2>
                            </div>
                            <p className="text">
                            At HSM Pharmaceutical Solution, we provide end-to-end turnkey project services, ensuring a seamless transition from concept to commissioning. Our expertise covers everything from detailed planning and project management to utility design, validation, and effective troubleshooting. We are dedicated to delivering tailored solutions that meet the highest industry standards, ensuring your pharmaceutical operations are efficient, compliant, and successful.
                                </p>
                                
                            <div className="shop-price">
                            <NavLink className="home2-button-2 link btn-hvr-anim-top" to="/Turnkey">Learn more</NavLink>
                                {/* <a href="#" className="shop home2-button-2"><span className="icon"><i className="fal fa-shopping-cart"></i></span> Go Shop</a> */}
                                {/* <p className="price">Price: <span className="color">$299.99</span></p> */}
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </section>


        <section className="home2-built-with-area">
            <div className="home2-built-bg wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="1500ms"><img src="img/product-showcase/built-bg.png" alt=""/></div>
            <div className="home2-built-banner wow fadeInLeft animated" data-wow-delay="900ms" data-wow-duration="1500ms">
                <img className="item-bounce" src="./img/product-showcase/Consulting.png"  width="510" height="600" alt="image"/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-12">
                        <div className="home2-built-with-content">
                            <div className="section-title-2">
                                <h6 className="intro">Service</h6>
                                <h2 className="title">Consultancy</h2>
                            </div>
                            <ul className="item-list" style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                                <li className="item">Ongoing Product Formulation and correction.</li>
                                <li className="item">New Product Formulation.</li>
                                <li className="item">Cost and Quality maintenance in Production.</li>
                                <li className="item">Regulatory Jobs.</li>
                                <li className="item">Documentation and Validation.</li>
                                <li className="item">SOP and Space Validation.</li>
                            </ul>
                            
                        </div>
                        <NavLink className="home2-button-2 link btn-hvr-anim-top" to="/Consultancy">Learn more</NavLink>
                    </div>
                </div>
            </div>
        </section>


        <section className="home2-subscribe-area">
            <div className="home2-subscribe-container">
                <div className="home2-subscribe-top-banner wow pulse" data-wow-iteration="infinite" data-wow-duration="2.0s" data-wow-delay="0.5s"><img src="img/product-showcase/subscribe-banner-top.png" alt=""/></div>
                <div className="home2-subscribe-left-banner wow pulse" data-wow-iteration="infinite" data-wow-duration="2.0s" data-wow-delay="0.5s"><img src="img/product-showcase/subscribe-banner-left.png" alt=""/></div>
                <div className="home2-subscribe-right-banner"><img className="item-bounce" src="img/product-showcase/subscribe-banner-right.png" alt=""/></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2 col-12">
                            <div className="section-title-21">
                                <h6 className="intro">HSM Pharma Solution</h6>
                                <h2 className="title">Innovative Solutions,  <br/>Reliable Machines</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    </div>
                </div>
            </div>
        </section>

        
    </div>
    <Footer></Footer>
    </>
  );
}

export default Home;


