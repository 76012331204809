import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './turnkey.css'; // Custom CSS for styling and animation

const Consultancy = () => {
  return (
    <div>
        <Navbar />
    
        <div className="turnkey-project-page">
        <div className="image-container">
        <img
          src="./img/product-showcase/Consulting.png" // Replace with actual image path
          alt="Turnkey Project"
          className="project-image"
        />
      </div>
      <div className="content-container">
        <h2 className="project-title">Consultancy</h2>
        <ul className="bullet-points">
            <li>Ongoing Product Formulation and correction.</li>
            <li>New Product Formulation.</li>
            <li>Cost and Quality maintenance in Production.</li>
            <li>Regulatory Jobs.</li>
            <li>Documentation and Validation.</li>
            <li>SOP and Space Validation.</li>
          </ul>
          </div>
      
    </div>
    <Footer />
    </div>
  );
};

export default Consultancy;
