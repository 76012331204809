import React from 'react'
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import { LiaStar,LiaStarHalfAltSolid,LiaStarSolid } from "react-icons/lia";

import './about.css';

function About() {
  return (
    <>
    <Navbar/>
    <section className="py-3 py-md-5">
      <div className="aboutpage container">
        <div className="row">
                  <div className="col-lg-6 offset-lg-3 col-md-10 offset-md-1 col-12">
                      <div className="section-title-2">
                        <br/>
                          <h6 className="intro">Information</h6>
                          <h2 className="title">About Us</h2><br/>
                          <p className="text">At HSM pharma solutions, quality speaks for itself. Simply take a look at our work and products, and you'll have no doubts about the exceptional standards we maintain. As a cornerstone of our business, our skilful design and development processes have positioned us as leaders in manufacturing and exporting high-quality pharmaceutical and nutraceutical machinery at both lab-scale and production-scale.</p>
                      </div>
                  </div>
        </div>
        <div className="aboutus-section">
        <div className="container">
            <div className="row">
                <div className="col-md-4 col-sm-6 col-xs-12">
                    <div className="aboutus">
                        <h2 className="aboutus-title">HSM Pharma Solutions</h2>
                        <p className="aboutus-text">We offer comprehensive turnkey solutions, ensuring full regulatory compliance with WHO, MHRA, PICS, and USFDA standards. From plant layout design to project management, product formulation, and validation, our expert team is dedicated to delivering the best in both machinery and service. Whether you're looking to enhance your production capabilities or streamline regulatory processes, our innovative approach ensures you achieve your goals with confidence.</p>
                        {/* <a className="aboutus-more" href="#">read more</a> */}
                    </div>
                </div>
                <div className="col-md-3 col-sm-6 col-xs-12">
                    <div className="aboutus-banner">
                        <img src="http://themeinnovation.com/demo2/html/build-up/img/home1/about1.jpg" alt=""/>
                    </div>
                </div>
                <div className="col-md-5 col-sm-6 col-xs-12">
                    <div className="feature">
                        <div className="feature-box">
                            <div className="clearfix">
                                <div className="iconset">
                                    <span className="icon"><LiaStarSolid style={{width:65, height:65}}/></span>
                                    
                                </div>
                                <div className="feature-content">
                                    <br/>
                                    <h4>Our Mission</h4>
                                    <p>We aim to innovate the pharmaceutical industry with advanced machinery, delivering reliable, efficient solutions that meet global standards.</p>
                                </div>
                            </div>
                        </div>
                        <div className="feature-box">
                            <div className="clearfix">
                                <div className="iconset">
                                <span className="icon"><LiaStarSolid style={{width:65, height:65}}/></span>
                                </div>
                                <div className="feature-content">
                                <br/>
                                    <h4>Who We Are</h4>
                                    <p>HSM Pharmaceutical Solution is a leader in designing and manufacturing high-quality pharmaceutical machines. Our expert team ensures top-notch performance and safety in every product.</p>
                                </div>
                            </div>
                        </div>
                        <div className="feature-box">
                            <div className="clearfix">
                                <div className="iconset">
                                <span className="icon"><LiaStarSolid  style={{width:65, height:65}}/></span>
                                </div>
                                <div className="feature-content">
                                <br/>
                                    <h4>What We Do</h4>
                                    <p>We provide cutting-edge machinery for blending, tableting, filling, and packaging pharmaceuticals, enhancing production efficiency and product integrity.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      </div>
    </section>
    <Footer/>
    </>
  )
}

export default About;