import React from 'react';
import Navbar from './Navbar.js';
import Footer from './Footer.js';
import { useNavigate } from 'react-router-dom';
import {NavLink} from "react-router-dom";



const Store = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/Product'); // Redirects to the Product page
  };

  return (
    <div>
      <Navbar />
      <div className="home2-sections-container">
        <section className="home2-tutorial-area">
          <div className="home2-tutorial-container">
            <img src="img/product-showcase/Tech tutorial.png" alt="Tutorial Image" />
            <div className="home2-tutorial-content">
              <h2 className="title">Explore Our Products</h2>
              <p className="text">
                Precision-engineered pharmaceutical machines designed to optimize and enhance your manufacturing processes.
              </p>
              <button className="home2-button-2 link btn-hvr-anim-top" onClick={handleButtonClick}>
                Explore
              </button>
            </div>
          </div>
        </section>

        <section className="home2-tutorial-area1">
          <div className="home2-tutorial-container">
            <img src="img/product-showcase/Customer.png" alt="Tutorial Image" />
            <div className="home2-tutorial-content">
              <h2 className="title">Turnkey Projects</h2>
              <p className="text">
                At HSM Pharmaceutical Solution, we provide end-to-end turnkey project services, ensuring a seamless transition from concept to commissioning.</p>
              
              <NavLink className="home2-button-2 link btn-hvr-anim-top" to="/Turnkey">Learn more</NavLink>
              
            </div>
          </div>
        </section>

        <section className="home2-tutorial-area1">
          <div className="home2-tutorial-container">
            <img src="./img/product-showcase/Consulting.png" alt="Tutorial Image" />
            <div className="home2-tutorial-content">
              <h2 className="title">Consultancy</h2>
              <p className="text">
                At HSM Pharmaceutical Solution, we provide end-to-end turnkey project services, ensuring a seamless transition from concept to commissioning.
              </p>
              <NavLink className="home2-button-2 link btn-hvr-anim-top" to="/Consultancy">Learn more</NavLink>
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Store;



