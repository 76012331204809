import React from 'react';

function Footer() {
  return (
    <>
      <footer className="footer2" style={{ marginTop: 100 }}>
        <div className="home2-footer-container">
          <div className="home2-footer-banner-top-left wow fadeInLeft animated" data-wow-delay="00ms" data-wow-duration="2s">
            <img src="img/product-showcase/footer-top-left.png" alt="" />
          </div>
          <div className="home2-footer-banner-top-right wow fadeInRight animated" data-wow-delay="00ms" data-wow-duration="2s">
            <img src="img/product-showcase/footer-top-right.png" alt="" />
          </div>
          <div className="home2-footer-banner-bototm-left wow fadeInUp animated" data-wow-delay="00ms" data-wow-duration="2s">
            <img src="img/product-showcase/footer-left-bottom.png" alt="" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-12">
                <div className="home2-footer-content">
                  <div className="logo">
                    <h1 className="animated-title">HSM Pharma Solutions</h1>
                  </div>
                  <p className="text">
                    HSM Pharmaceutical Solution is at the forefront of pharmaceutical machinery innovation. Our commitment to quality and cutting-edge technology ensures that our products meet the highest industry standards. Explore our solutions and discover how we can support your manufacturing needs.
                  </p>
                  <div className="contact-info">
  <p className="contact-text">Email: 
    <a href="mailto:info@hsmpharmasolutions.com" className="contact-link">info@hsmpharmasolutions.com,<br/></a>
    <span className="separator"></span>
    <a href="mailto:info.hsmps@gmail.com" className="contact-link">info.hsmps@gmail.com</a>
  </p>
  <p className="contact-text">Contact: 
    <a href="tel:+919106291200" className="contact-link">+91 91062 91200,</a>
    <a href="tel:+919714526822" className="contact-link">+91 97145 26822</a>
  </p>
</div>


                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="copyright">
            <p className="text">© 2023 HSM Pharma Solutions. All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
