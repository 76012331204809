import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import './product.css';

// Array of products
const products = [
  {
    name: 'VIBRO SIFTER 12"',
    model: 'CSVS - 12',
    features: [
      'Machine Design as per cGMP.',
      'All Contact parts SS316 with and Non-Contact parts with SS 304 or clad with SS304.',
      'Basic machine Non Flame Proof Construction.',
      'Machine on PU Castor Wheels.',
      'Easily changeover sieve & cleaning process using Clamps.'
    ],
    image: './img/product-showcase/Shifter.png',
  },
  {
    name: 'RAPID MIXER GRANULATOR',
    model: 'CSRMG - 10',
    features: [
      'Machine Design as per cGMP.',
      'With Interchangeable design of Mixing bowl.',
      'All Contact parts AISI SS316 with Mirror Finish.',
      'Basic machine with Non Flame Proof Construction.',
      'Table Top Machine design.'
    ],
    image: './img/product-showcase/Blender1.png',
  },
  {
    name: 'FLUID BED PROCESSOR(FBD/FBP)',
    model: 'CSFMP - 22',
    features: [
      'Batch Size - 450gm - 2.25kg.',
      'Particle Size >100μm.',
      'Air Flow - 210 CMH.',
      'Size; 550 X 550 X 750.',
      'Machine Design as per cGMP.'
    ],
    image: './img/product-showcase/Blender1.png',
  },
  {
    name: 'CO - MILL',
    model: 'CSMil',
    features: [
      'The Milling process is required in order to reduce particle size of powder.',
      'Machine Design as per cGMP.',
      'A rotating impeller forces the material outward to a conical screen surface, where it is sized and the screen. Once finished, the product simply drops through the milling chamber to a receptacle underneath.'
    ],
    image: './img/product-showcase/Blender1.png',
  },
];

// Component to display individual product card
const ProductCard = ({ product }) => (
  <div className="product-card">
    <img src={product.image} alt={product.name} className="product-image" />
    <div className="product-info">
      <h3>{product.name}</h3>
      <p>Model: {product.model}</p>
      <h4>Machine Features</h4>
      <ul>
        {product.features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
    </div>
  </div>
);

// Main product page component
const Product = () => (
  <div className="product-page">
    <Navbar />
    {products.map((product, index) => (
      <ProductCard key={index} product={product} />
    ))}
    <Footer />
  </div>
);

export default Product;
