import React, { useState, useEffect } from "react";
import {NavLink} from "react-router-dom";
import './Navbar.css';
import { PiListBold } from "react-icons/pi";
import { IoCloseSharp } from "react-icons/io5";

const Navbar = () => {
    function myFunction() {
        var x = document.getElementById("navbar");
        if (x.className === "navbar main-menu stellarnav light right") {
          x.className = "navbar main-menu stellarnav light right mobile active";
        } else if(x.className === "navbar main-menu stellarnav light right mobile active") {
          x.className = "navbar main-menu stellarnav light right";
        } else {
          x.className = "navbar main-menu stellarnav light right";
        }
    }
    
  return (
    <>
   
    <header className="h2-header">
        <div className="home2-header-container header-container">
            <div className="row"> 
                <div className="col-lg-3 col-sm-3 col-9"> 
                    <div className="logo" style={{paddingTop: 10}}>
                    <h1 style={{ fontSize: '20px', color: '#000'}}>HSM Pharma Solutions</h1>
                    </div>
                 </div>
                <div className="" style={{marginLeft:"auto"}}> 
                <div id="navbar" className="navbar main-menu stellarnav light right">
                    <ul>
                        <li className="">
                            <NavLink className="" to="/">Home</NavLink>
                        </li>
                        <li >
                            <NavLink className="active" to="/Store">Our Products</NavLink> 
                        </li>
                        
                        <li>
                            <NavLink className="" to="/About">About</NavLink> 
                        </li>
                        <li>
                            <NavLink className="" to="/Contact">Contact</NavLink> 
                        </li>
                        <li className="mobilenavbar">
                            <br/><br/><br/><br/><br/><br/>
                            <i onClick={() => myFunction()} id="myTopnav" className="closebutton"><IoCloseSharp /></i>
                            <br/><br/><br/>  
                        </li>
                    
                    </ul>
                <i onClick={() => myFunction()} id="myTopnav" className="bi bi-list mobile-nav-toggle"><PiListBold /></i>
                </div> 
                </div>
                </div>
        </div>
    </header>
    </>
  )
  
}


export default Navbar;